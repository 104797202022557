import { Picture } from '~/components/ui/Picture';

export const Logo: React.FC<{ resourcesUrl: string }> = ({ resourcesUrl }) => {
	return (
		<Picture
			altText="Oddschecker Logo"
			height={34}
			svgSrc={`${resourcesUrl}/assets/img/oc-logo.png`}
			width={213}
		/>
	);
};
