import type { CSSProperties } from 'react';

import styles from './Picture.module.css';

export interface PictureProps {
  altText: string;
  className?: string;
  height?: number;
  lazyLoad?: boolean;
  png2xSrc?: string;
  pngSrc?: string;
  style?: CSSProperties;
  svgSrc?: string;
  webp2xSrc?: string;
  webpSrc?: string;
  width?: number;
}

export const Picture = ({
  altText,
  className,
  height,
  lazyLoad,
  png2xSrc,
  pngSrc,
  style,
  svgSrc,
  webp2xSrc,
  webpSrc,
  width
}: PictureProps): JSX.Element => {
  const pngSrcProps = {
    type: 'image/png',
    [lazyLoad ? 'data-srcset' : 'srcSet']: `${pngSrc}${
    png2xSrc ? `, ${png2xSrc} 2x` : ''}`

  };

  const webpSrcProps = webpSrc ?
  {
    type: 'image/webp',
    [lazyLoad ? 'data-srcset' : 'srcSet']: `${webpSrc}${
    webp2xSrc ? `, ${webp2xSrc} 2x` : ''}`

  } :
  {};

  const imgProps = {
    alt: altText,
    height,
    width,
    ...(lazyLoad ?
    {
      className: `${className ? `${className} ` : ''}lazyload`
    } :
    className ?
    { className } :
    {}),
    [lazyLoad ? 'data-src' : 'src']:
    typeof svgSrc !== 'undefined' ? svgSrc : pngSrc
  };

  return typeof svgSrc !== 'undefined' ?
  <picture>
			<img
      className={styles.pictureImage}
      {...imgProps}
      alt={altText}
      style={style} />

		</picture> :

  <picture>
			{!!webpSrc && <source {...webpSrcProps} />}
			<source {...pngSrcProps} />
			<img
      className={styles.pictureImage}
      {...imgProps}
      alt={altText}
      style={style} />

		</picture>;

};